<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="user === undefined"
    >
      <h4 class="alert-heading">
        خطا در برقراری ارتباط
      </h4>
      <div class="alert-body">
        کاربری با این شماره پیدا نشد. برای دیدن کاربران به بخش
        <b-link
          class="alert-link"
          :to="{ name: 'users'}"
        >
          لیست کاربران
        </b-link>
        مراجعه فرمایید.
      </div>
    </b-alert>

    <template v-if="user">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="user" />
        </b-col>
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="5"-->
        <!--          xl="3"-->
        <!--          lg="4"-->
        <!--        >-->
        <!--          <user-view-user-plan-card />-->
        <!--        </b-col>-->
      </b-row>

      <!--      <b-row>-->
      <!--        <b-col-->
      <!--          cols="12"-->
      <!--          lg="6"-->
      <!--        >-->
      <!--          <user-view-user-timeline-card />-->
      <!--        </b-col>-->
      <!--        <b-col-->
      <!--          cols="12"-->
      <!--          lg="6"-->
      <!--        >-->
      <!--          <user-view-user-permissions-card />-->
      <!--        </b-col>-->
      <!--      </b-row>-->

    <!--      <invoice-list />-->
    </template>

  </div>
</template>

<script>
import {
  BRow, BCol,
  BAlert, BLink,
} from 'bootstrap-vue'

// import InvoiceList from '@/views//invoice/invoice-list/InvoiceList.vue'
// import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
// import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
// import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

// import axiosIns from '@/libs/axios'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    // UserViewUserPlanCard,
    // UserViewUserTimelineCard,
    // UserViewUserPermissionsCard,
  },
  data() {
    return {
      user: {},
      user_id: this.$route.params.id,
    }
  },
  mounted() {
    this.getUserData()
  },
  methods: {
    async getUserData() {
      const response = await this.$axios(`/admin-panel/users/${this.$route.params.id}`)
      this.user = response.data
    },
  },
  // setup() {
  //   const userData = ref(null)
  //
  //   const USER_APP_STORE_MODULE_NAME = 'app-user'
  //
  //   // Register module
  //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
  //
  //   // UnRegister on leave
  //   onUnmounted(() => {
  //     if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  //   })
  //
  //   store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
  //     .then(response => { userData.value = response.data })
  //     .catch(error => {
  //       if (error.response.status === 404) {
  /*        userData.value = undefined */
  //       }
  //     })
  //
  //   return {
  //     userData,
  //   }
  // },
}
</script>

<style>

</style>
